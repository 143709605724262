import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import CollectAddressInputBar from '../CollectAddressInputBar';
import Loading from '../components/LoadingIcon';

const ScheduleModal = ({ activemod, handleClose, setCustomerEmail, setCustomerPhone, setCustomerName, handleChangeObject, setPreferred, setPresentForQuoteVerification, handleSubmit, showinputflag, children, content, title, email, email_regex, phone, phone_regex, customername, preferredcontact, emailsent, address, afterquote_link, isdemo, industry, values_customer, values_business}) => {

    console.log('ScheduleModal')
    console.log(email_regex)
    console.log(phone_regex)
    const show = (activemod === title);

    /*-Matomo Analytics-*/
    if (show){
        const title = 'schedulenow';
        Matomo(title);
    }
    /*----End Matomo----*/
    
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const enablecheckboxes =  email && email_regex.test(email) && phone && phone_regex.test(phone) && customername && address;
    //console.log('enablecheckboxes: ', enablecheckboxes)
    //console.log('email regex: ', email_regex.test(email))
    
    const getUserInputFlag = () => {
        if(showinputflag){
            let initial_prompt = 'Please enter a valid'
            let flag = initial_prompt;
            if (!customername){
                flag += ' name'
            } 
            if (!phone || !phone_regex.test(phone)){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' phone (with area code)'
            }
            if (!email || !email_regex.test(email)){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' email'
            }
            if (!address){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' address'
            }
            if (!preferredcontact){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' preferred contact method'
            }
            if (flag.length > initial_prompt.length){
                return flag;
            } else {
                return null;
            }
        }
    }
    const afterQuoteRedirect = (afterquote_link) => {
        //visit link
        window.location.href = afterquote_link;
    }

    const isScheduledFollowUpJobtype = () => {
        const check_against = values_business.jobtypes_without_scheduled_followup;
        if (check_against){ //industries with check_against are only asphalt currently
            for (let i = 0; i < check_against.length; i++){
                if (values_customer.jobtype.title.includes(check_against[i])){
                    return false;
                }
            }
            return true;
        }
    }

    const renderForm = () => {
        
        return (
            <section className="modal-schedule" id="modal-schedule">
                <CSSTransition
                    in={show}
                    timeout={150}
                    classNames="fade-fast"
                    unmountOnExit 
                    appear
                    //onEntered={}
                    //onExit={}
                >
                    <div className='column'>
                        <div className="right">
                            <div className="close-modal-icon" onClick={handleClose}>
                                <FontAwesomeIcon 
                                    className='large no-margin'
                                    icon={faTimes}
                                    color='black'
                                />
                            </div>
                        </div>
                        <h2 className='large'>{title}</h2>
                        {content}
                        {children}
                        {isdemo && <p className='x-small italic invalid'>[DEMO ONLY NOTE]. For this demo, we'll send a copy of both the "customer" and "company" copies of the quote to whatever email you enter. Normally, the customer will only recieve their copy, and you recieve yours.</p>}
                        <p className='x-small invis'>.</p>
                        {/* <p className='x-small left light italic'>Note that this is only an <b>estimate</b>, does not include tax, and is subject to final validation of all measurements and other provided work area conditions.</p> */}
                        <div className="formwrapper">
                            <input 
                                type="text"
                                name="name" 
                                autoComplete="name"
                                placeholder= 'Name'
                                value = {customername}
                                alt = 'your first and last name'
                                onChange = {setCustomerName}
                                //data-Matomo-mask 
                            />
                            <p className = {(customername) ? 'miniplaceholder' : 'miniplaceholder invis'}>Name*</p>
                        </div>
                        <div className="formwrapper">
                            <input 
                                type="tel" 
                                name="tel"
                                autoComplete="tel"
                                placeholder= 'Phone'
                                value = {phone}
                                alt = 'best phone number to reach you at'
                                onChange= {setCustomerPhone}
                                //data-Matomo-mask 
                            />
                            <p className = {(phone) ? 'miniplaceholder' : 'miniplaceholder invis'}>Phone*</p>
                        </div>
                        <div className="formwrapper">
                            <input 
                                type="email"
                                name="email"
                                autoComplete="on"
                                placeholder= 'Email'
                                value = {email}
                                alt = 'email address to send a copy of your quote to'
                                onChange= {setCustomerEmail}
                                //data-Matomo-mask 
                            />
                            <p className = {(email) ? 'miniplaceholder' : 'miniplaceholder invis'}>Email*</p>
                        </div>
                        <CollectAddressInputBar customer_address={address} className='formwrapper' handleChangeObject={handleChangeObject} ismodal={true}/> {/* TODO: address dropdown is not showing! */}
                        {/*<div className="formwrapper">
                            <input 
                                type="text"
                                name="address"
                                autoComplete="on"
                                placeholder= 'Street Address'
                                value = {address}
                                alt = 'your street address'
                                onChange= {setCustomerAddress}
                                data-Matomo-mask 
                            />
                            <p className = {(address) ? 'miniplaceholder' : 'miniplaceholder invis'}>Street Address*</p>
                        </div>*/}
                        <div className="contact">
                            <p className='contacttext'>Preferred contact method: </p>
                            <div className='preferences'>
                                <div className='boxgroupitem'>
                                    <input 
                                        className = "checkbox" 
                                        type="checkbox" 
                                        id="call" 
                                        name="contact" 
                                        value="Call" 
                                        onClick={setPreferred} 
                                        checked={(preferredcontact==="Call")}
                                        //disabled={!enablecheckboxes}
                                        />
                                    <label for="call">&nbsp;Call</label>
                                </div>
                                <div className='boxgroupitem'>
                                    <input 
                                        className = "checkbox"
                                        type="checkbox" 
                                        id="text" 
                                        name="contact" 
                                        value="Text" 
                                        onClick={setPreferred} 
                                        checked={(preferredcontact==="Text")}
                                        //disabled={!enablecheckboxes}
                                        />
                                    <label for="text">&nbsp;Text</label>
                                </div>
                                <div className='boxgroupitem'>
                                    <input     
                                        className = "checkbox"
                                        type="checkbox" 
                                        id="email" 
                                        name="fav_language" 
                                        value="Email" 
                                        onClick={setPreferred} 
                                        checked={(preferredcontact==="Email")}
                                        //disabled={!enablecheckboxes}
                                        />
                                    <label for="email">&nbsp;Email</label>
                                </div>
                            </div>
                        </div>
                        {isScheduledFollowUpJobtype() &&
                             <div className='options-dropdown formwrapper column' style={{marginTop:'15px'}}>
                                <label for="stopby"><b>Before scheduling your job</b> we may need to stop by in person to verify your quote measurements and conditions. If so, would you like to be present for this?</label>
                                <select id="stopby" name="stopby" selected={values_customer.present_for_quote_verification} onChange={setPresentForQuoteVerification}>
                                    <option value="yes" selected={values_customer.present_for_quote_verification === 'yes'}>Yes, contact me to schedule a time</option>
                                    <option value="no" selected={values_customer.present_for_quote_verification === 'no'}>No, I don't need to be there, stop by whenever</option>
                                </select>
                            </div>}
                        {!isScheduledFollowUpJobtype() && title !== 'Partner Quote Request' && <p className='x-small light' style={{marginBottom: '-10px', marginTop: '20px'}}>Thank you for your interest in our services. Our office staff will reach out to you shortly to verify your quote and discuss scheduling details. We look forward to serving you!</p>}
                        <div className='submitbtnwrapper'>
                            <p className='small invalid'>{getUserInputFlag()}</p>
                            <button
                                className='formsubmitbtn'
                                //disabled = {!enablecheckboxes || !preferredcontact}
                                onClick={handleSubmit}>
                                My contact information is correct
                                {enablecheckboxes && preferredcontact ? 
                                    <FontAwesomeIcon 
                                        icon={faArrowRight}
                                    /> 
                                    : ''
                                    }
                            </button>
                        </div>
                    </div>
                </CSSTransition>
            </section>
        );
    }
    const renderEmailSent = () => {
        if (afterquote_link){
            afterQuoteRedirect(afterquote_link)
            return(
                <div className='center'>
                    <Loading/>
                </div>
            )
        } else if (title !== 'Partner Quote Request'){ //request quote is for external leads
            return(
                <section className="modal-email">
                    <div className="right">
                    <FontAwesomeIcon //TODO: change to header, so even when modal is shrunk and scrolled this is on top
                        icon={faTimes}
                        color='grey'
                        size='lg'
                        onClick={handleClose}
                            />
                    </div>
                    <div className='center'>
                        <h2 className='large'>Someone will be in touch with you soon to confirm your details & schedule this job</h2>
                        <p /*data-Matomo-mask*/ className='small light'>A copy of this quote has also been sent to you through the email you provided ({email})</p>
                        <p className='x-small'>If you don't see a copy of your quote, please make sure to check all inbox folders.</p>
                        {/* {(afterquote_link) ? <a className='after-quote-btn bold m-small' href={afterquote_link}>Continue to Next Steps</a> : ''} */}
                        {children}
                    </div>
                </section>
            );
        } else {
            return(
                <section className="modal-email">
                    <div className="right">
                    <FontAwesomeIcon //TODO: change to header, so even when modal is shrunk and scrolled this is on top
                        icon={faTimes}
                        color='grey'
                        size='lg'
                        onClick={handleClose}
                            />
                    </div>
                    <div className='center'>
                        <h2 className='large'>Thank you!</h2>
                        <p /*data-Matomo-mask*/ className='small light'>We've recieved your job information and will work on finishing your quote through one of our moving partners local to you.</p>
                        <p className='x-small'>Someone will reach out to you within the next business day or two.</p>
                        {children}
                    </div>
                </section>
            );
        }
    }


    return (
        <div className={showHideClassName}>
            {(emailsent) ? renderEmailSent() : renderForm()}
        </div>
    );
};

export {ScheduleModal};