import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import Loading from '../components/LoadingIcon';

const EmailModal = ({ activemod, handleClose, setCustomerEmail, setCustomerName, setCustomerPhone, setPermissionedFollowup, handleSubmit, children, content, title, email, email_regex, customername, phone, emailsent, afterquote_link, isdemo, permissioned_followup,  }) => {
    const show = (activemod === title);
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    /*-Matomo Analytics-*/
    if (show){
        const title = 'savequote';
        Matomo(title);
    }
    /*----End Matomo----*/

    const valid_input = email && customername && email_regex.test(email);

    const renderForm = () => {
        
        return (
            <section className="modal-email">
                <CSSTransition
                    in={show}
                    timeout={150}
                    classNames="fade-fast"
                    unmountOnExit 
                    appear
                    //onEntered={}
                    //onExit={}
                >
                    <div className='column'>
                        <div className="right">
                            <div className="close-modal-icon" onClick={handleClose}>
                                <FontAwesomeIcon 
                                    className='large no-margin'
                                    icon={faTimes}
                                    color='black'
                                />
                            </div>
                        </div>
                        <h2 className='large'>{title}</h2>
                        {content}
                        {children}
                        {isdemo && <p className='x-small italic invalid'>[DEMO ONLY NOTE]. For this demo, we'll send a copy of both the "customer" and "company" copies of the quote to whatever email you enter. Normally, the customer will only recieve their copy, and you recieve yours.</p>}
                        <div className="formwrapper">
                            <input 
                                type="text"
                                name="name" 
                                autoComplete="name"
                                placeholder= 'Name'
                                value = {customername}
                                alt = 'your first and last name'
                                onChange = {setCustomerName}
                                //data-Matomo-mask 
                            />
                            <p className = {(customername) ? 'miniplaceholder' : 'miniplaceholder invis'}>Name</p>
                        </div>
                        {/*
                        <div className="formwrapper">
                            <input 
                                type="tel" 
                                name="tel"
                                autoComplete="on"
                                placeholder= {(phone) ? phone : 'Phone'}
                                alt = 'best phone number to reach you at'
                                onChange= {setCustomerPhone}
                                data-Matomo-mask 
                            />
                            <p className = {(phone) ? 'miniplaceholder' : 'miniplaceholder invis'}>Phone</p>
                        </div>
                        */}
                        <div className="formwrapper">
                            <input 
                                type="email"
                                name="email"
                                autoComplete="on"
                                placeholder= 'Email'
                                value = {email}
                                alt = 'email address to send a copy of your quote to'
                                onChange= {setCustomerEmail}
                                //data-Matomo-mask 
                            />
                            <p className = {(email) ? 'miniplaceholder' : 'miniplaceholder invis'}>Email</p>
                        </div>
                        {permissioned_followup !== null && permissioned_followup !== undefined && <div className='formwrapper row flex-start nowrap' style={{marginTop:'15px', marginBottom:'5px' }}>
                            <div className='switch' style={{width: '10%', justifySelf:'flex-start', paddingRight:'5px', minWidth: '50px'}}>
                                <input 
                                    type="checkbox" 
                                    id="toggle-btn" 
                                    value={permissioned_followup === 'true' ? 'false' : 'true'} //toggle value
                                    checked={permissioned_followup === 'true'} 
                                    onClick={setPermissionedFollowup}/>
                                <label for="toggle-btn"></label>
                            </div> 
                            <p className='x-small align-self-center' style={{marginBottom:'0px', width: 'max-content', maxWidth: '80%', justifySelf:'center', textWrap: 'wrap'}}>Verify these quote details & follow up with me later</p>
                        </div>}

                        <p className='x-small left light'>Note that this is only an <b>estimate</b>, does not include tax, and is subject to in-person validation of all measurements and other provided work area conditions.</p>
                        <button
                            className='formsubmitbtn'
                            disabled = {!valid_input}
                            onClick={handleSubmit}>
                            My contact information is correct
                            {valid_input ? 
                            <FontAwesomeIcon 
                                icon={faArrowRight}
                            /> 
                            : ''
                            }
                        </button>
                    </div>
                </CSSTransition>
            </section>
        );
    }

    const afterQuoteRedirect = (afterquote_link) => {
        //visit link
        window.location.href = afterquote_link;
    }

    const renderEmailSent = () => {
        if (afterquote_link){
            afterQuoteRedirect(afterquote_link)
            return(
                <div className='center'>
                    <Loading/>
                </div>
            )
        } else {
            return(
                <section className="modal-email">
                    <div className="right">
                    <FontAwesomeIcon //TODO: change to header, so even when modal is shrunk and scrolled this is on top
                        icon={faTimes}
                        color='grey'
                        size='lg'
                        onClick={handleClose}
                            />
                    </div>
                    <div className='center'>
                        <h2 className='large'>Your information has been sent to you through the email you provided ({email})</h2>
                        <p className='medium light'>If you don't see a copy of your quote soon, please make sure to check all inbox folders.</p>
                        {(afterquote_link) ? <a className='after-quote-btn bold m-small' href={afterquote_link}>Continue to Next Steps</a> : ''}
                        {children}
                    </div>
                </section>
            );
        }
    }

    return (
        <div className={showHideClassName}>
            {(emailsent) ? renderEmailSent() : renderForm()}
        </div>
    );
};

export {EmailModal};