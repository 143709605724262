import { React, Component } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import '../App.css';
import  ConcreteCoatingSurvey  from './ConcreteCoatingSurvey';
import AsphaltSurvey from './AsphaltSurvey';
import  ScheduleContact from './ScheduleContact';
import ConcreteOnboardingSurvey from './concrete_coating_onboarding/ConcreteOnboardingSurvey';
import GPTModal from './general_questions/modals/GPTmodal';
import GPTChatSession from './general_questions/components/GPTChatSession';
import PianoSurvey from './PianoSurvey';
import AnalyticsHook from './AnalyticsHook';
import MapReDraw from './MapReDrawHook';
import { LoadScript, LoadScriptNext } from '@react-google-maps/api';

const google_maps_libraries = ['places'];

export class MasterSurvey extends Component {
    constructor () {
        super();
        this.state = {
            is_mobile_display: null, //user is using a mobile device
            mobile_breakpoint: 830,
            businessid: '',
            window_dimensions: null,
            mounted: false,
            map_loaded: false,
        }
    }

    // getWindowDimensions = () => {
    //     const is_mobile_displaydevice = (window_dimensions && window_dimensions.innerWidth < mobile_breakpoint)
    //     //this.setState({ is_mobile_display : is_mobile_displaydevice }); 
    //     return is_mobile_displaydevice;
    // }

    updateWindowDimensions = () => {
        const window_dimensions = window.innerWidth;
        const is_mobile_displaydevice = window_dimensions && window_dimensions < this.state.mobile_breakpoint;
        console.log(window_dimensions)
        console.log(is_mobile_displaydevice)
        this.setState({window_dimensions: window_dimensions, is_mobile_display: is_mobile_displaydevice});
    }

    componentDidMount = () => {
        const biz = this.props.subdomain; 
        //const devemail = (biz.length === 0) ? 'wodchasing@gmail.com' : null;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.initBizID();
        this.setState({mounted: true});
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    initBizID = () => {
        let host = window.location.host;
        let parts = host.split(".");
        let subdomain = "";
        let domain = "";
        let key = "";
        // If we get more than 3 parts, then we have a subdomain
        // INFO: This could be 4, if you have a co.uk TLD or something like that.
        console.log(parts)
        if (parts.length >= 3) {
          subdomain = parts[0];
          domain = parts[1];
          console.log("subdomain: " + subdomain)
          if(subdomain === 'quotes') { //ex quotes.croccoatings.com
            console.log("domain: " + domain)
            key = domain;
          } else {
            key = subdomain;
          }
        }
        const DEV_ID = 'hellerbrothers';
        const id = (key.length > 0) ? ( (key.includes('3000')) ? DEV_ID : key ) : DEV_ID; //when testing on localhost// or github preview url
        /*this.setState({
            businessid: id,
        });*/
        return id;
    }

    render() { 

        const {is_mobile_display, businessid, mounted, map_loaded} = this.state;

        const sched_job_concretecoatings_api = 'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/schedulejob';
        const send_email_api = 'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/savequote';
        const get_concretecoatings_quote_api = 'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/savequote?';
        console.log('google_maps_libraries: ' + google_maps_libraries)
        
        //maybe is exact path is /, lookup what kind of react component to return dynamically. Saves headache of bad url                                                                                  
        return (
            <HashRouter>
                <div className="App">    
                    <Routes>                         
                        <Route exact path = '/concretecoating' element={<ConcreteCoatingSurvey key='concretecoatings' subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries}/> } />
                        <Route exact path = '/concretecoating/embed' element={<ConcreteCoatingSurvey key='concretecoatings' subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries} embedded={true}/> } />
                        <Route exact path = '/asphalt' element={<AsphaltSurvey  subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries}/> }/>
                        <Route exact path = '/asphalt/embed' element={<AsphaltSurvey  subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries} embedded={true}/> }/>
                        <Route exact path = '/piano-moving' element={<PianoSurvey  subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries}/> }/>
                        <Route exact path = '/piano-moving/embed' element={<PianoSurvey  subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries} embedded={true}/> }/>
                        <Route exact path = '/concretecoating/onboarding' element={<ConcreteOnboardingSurvey subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display}/>} />
                        <Route exact path = '/' element={<ConcreteCoatingSurvey  subdomain = {this.initBizID()} is_mobile_display = {this.state.is_mobile_display} google_maps_libraries = {google_maps_libraries}/>} />
                
                        <Route path = '/schedulecontact/:industry/:quoteid' 
                               element={
                                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={this.setMapLoaded} libraries={google_maps_libraries}>
                                    <ScheduleContact
                                            ismobile = {is_mobile_display}
                                            sched_job_api = {sched_job_concretecoatings_api}
                                            send_email_api = {send_email_api}
                                            get_quoteinfo_api = {get_concretecoatings_quote_api}
                                            values_customer = {{email: null, phone: null, customername: null, address: null}}
                                            email_regex = {new RegExp(
                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
                                            )}
                                            phone_regex = {new RegExp(  //1-xxx-xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, xxx xxx xxxx etc.
                                            /^(?:1(?:[ -]?\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{3}[ -]?\(\d{3}\)[ -]?\d{4})|\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{10}|\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/
                                            )}
                                            handleChangeObject={() => {}}
                                            // google_maps_libraries = {google_maps_libraries}
                                    />
                                </LoadScript>
                        } />
                        <Route path = '/viewmap/:industry/:quoteid/' 
                            element={
                                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={google_maps_libraries}>
                                    <MapReDraw
                                        subdomain = {this.initBizID()}
                                        is_mobile_display = {this.state.is_mobile_display}
                                    />
                                </LoadScript>
                            } 
                        />

                        {/* LEGACY, delete after concrete coatings deployment after 16 Feb 24 */}
                        {/* <Route path = '/schedulecontact/:quoteid' 
                               element={<ScheduleContact
                                    sched_job_api = {sched_job_concretecoatings_api}
                                    get_quoteinfo_api = {get_concretecoatings_quote_api}
                                    values_customer = {{email: null, phone: null, customername: null, address: null}}
                                    email_regex = {new RegExp(
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
                                    )}
                                    phone_regex = {new RegExp(  //1-xxx-xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, xxx xxx xxxx etc.
                                    /^(?:1(?:[ -]?\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{3}[ -]?\(\d{3}\)[ -]?\d{4})|\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{10}|\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/
                                    )}
                               />
                        } /> */}
                        {/* END LEGACY */}


                        <Route exact path = '/zapier-api-documentation' element={<GPTModal />} />

                        {/* ANALYTICS */}
                        <Route exact path = '/anlys/:industry/:action/:quoteid/:businessphone' element={<AnalyticsHook/>} /> 

                        <Route exact path = '/concrete-assistant-beta/5TJd735SSDpc%3F'
                                element={
                                    <div className='surveywrapper' style={{width: '90%', }}>
                                        <div className='modal-main'>
                                        <GPTChatSession 
                                            prompts = {[
                                                    {   
                                                        display_text: `What can you help me with?`,
                                                        gpt_query: `What can you help me with?`,
                                                    },
                                                    {
                                                        display_text: `How do I measure square footage?`,
                                                        gpt_query: `How do I measure square footage?`,
                                                    }
                                                ]
                                            } 
                                            intro_query={null} 
                                            company_name={(this.initBizID() === 'hitechcoatings') ? 'Hi-Tech Coatings' : null} 
                                            industry={'concrete coatings'}
                                        />
                                        </div>
                                    </div>
                                }
                        />
                    </Routes>
                </div>                     
            </HashRouter>
        )
    }
}

export default MasterSurvey;