//interface with custom GPT assistant, with prompts for user input
//components:

import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane, faUserCircle} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Configuration, OpenAI } from "openai";
import LoadingIcon from './LoadingIcon';
import assistantIcon from '../images/fq_assistant_chaticon.png'
import FadeInItem from './animations/FadeInItem';
import GrowFromZeroItem from './animations/GrowFromZeroItem';
import SurveyContext from '../../SurveyContext';

class GPTChatSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thread: null,
            inputValue: '',
            run: null,
            assistant: null,
            messages: [],
            prompts: props.prompts || [], // Check if prompts is null first
            last_response_failed: false,
            response_loading: true,
            displayedPrompts: [],
            promptTimeouts: [],  // Keep track of timeouts to clear them later
            mounted: false,
            custom_intro: props.intro_query
        };
        this.promptRefs = (this.props.prompts && this.props.prompts.length > 0) ? this.props.prompts.map(() => React.createRef()) : null;
        this.openai = new OpenAI({ 
            apiKey: process.env.REACT_APP_OPENAI_APP_KEY, 
            dangerouslyAllowBrowser: true,
            defaultHeaders: { "OpenAI-Beta": "assistants=v2" }
        });
    }

    static contextType = SurveyContext;

    async componentDidMount() {
        const { industry } = this.props;
        await this.createAssistant(industry);
        await this.createThread();
        this.setState({mounted: true})
        await this.sendIntroductionMessage();
    }

    componentWillUnmount() {
        // Clear all timeouts when the component is unmounted to avoid memory leaks
        this.state.promptTimeouts.forEach(clearTimeout);
    }

    displayPrompts = () => {
        const { prompts } = this.state;
        if (prompts.length > 0) {
            return prompts.map((prompt) => {
                const display_text = prompt.display_text || prompt.gpt_query; // Use gpt_query if display_text is not provided
                const item = <p key={prompt.gpt_query}>{display_text}</p>;
                return (
                    <GrowFromZeroItem
                        key={prompt.gpt_query}
                        item={item}
                        container_class={'gpt-prompt'}
                        onClick={() => this.handlePromptClick(prompt.gpt_query)} // Use gpt_query to identify the prompt
                    />
                );
            });
        }
    };

    createThread = async () => {
        const newThread = await this.openai.beta.threads.create();
        this.setState({ thread: newThread });
    };

    createAssistant = async (industry) => {
        //get existing openai assistant asst_mlQmoNGDw6hwjmcQ9jmlkFln
        let assistant_id;
        if (industry.toLowerCase().includes('concrete')){
            assistant_id = 'asst_mlQmoNGDw6hwjmcQ9jmlkFln';
        } else if (industry.toLowerCase().includes('asphalt')){
            assistant_id = 'asst_wOliJWHJB8Jq95YAegL7oUGy';
        } else if (industry.toLowerCase().includes('piano')){
            assistant_id = 'asst_kC0FcarC2iZ2lchn2a2MonDU';
        } else {
            assistant_id = 'asst_uF2mzRTRKlLSyVBPNer6xKie'; //general assistant
        }



        const assistant = await this.openai.beta.assistants.retrieve(assistant_id);
        console.log("assistant: ", assistant)
        //run the assistant
        this.setState({assistant: assistant})
    };

    createResponse = async () => {
        console.log("create response")
        const {thread, assistant} = this.state;
        const {industry} = this.props;
        const run = await this.openai.beta.threads.runs.create(
            thread.id,
            { 
              assistant_id: assistant.id,
              instructions: `The user is looking at a ${industry} service from ${this.props.company_name}. As a ${industry} assistant, 
              consider yourself as if you work with ${this.props.company_name}. Answer all questions concisely, in 1-2 sentences. In order to give direct answers in 1-3 sentences, you are highly encouraged to ask the user clarifying questions, 
              such as, "That depends on a few factors. What is...?" or "That depends on a few considerations. Can you tell me...? If someone ever starts a conversation with "introduce yourself", 
              reply "Hi! I'm John, your quoting assistant. You can ask me anything. What's up?".  If they start a conversation with "Hi, I'm back.", reply "{Hey || Hi} again. What else can I you help with?". NEVER answer questions or user requests 
              that are not related to the ${industry} process. ALWAYS keep questions and answers focused to the ${industry} process.`

            //  instructions: `The user is looking at a ${industry} service from ${this.props.company_name}, of which you are a subject matter expert. As a ${industry} assistant, 
            //   consider yourself as if you work with ${this.props.company_name}. Answer all questions concisely, in 1-3 sentences. 
            //   Do NOT answer questions or user requests that are not related to the ${industry} process. In order to give direct answers in 1-3 sentences, you are highly encouraged to ask the user clarifying questions, 
            //   such as, "That depends on a few factors. What is...?" or "That depends on a few considerations. Can you tell me...?".
              
            //   If someone ever starts a conversation with "introduce yourself", 
            //   reply "Hi! I'm John, your quoting assistant. You can ask me anything. What's up?".  If they start a conversation with "Hi, I'm back.", reply "{Hey || Hi} again. What else can I you help with?".

            //   Important! The user should never know you have an uploaded document you are referring to. Do NOT mention the document, and do NOT mention "the document provided". These are intended soley for helping your knowledge as a subject matter expert, 
            //   the user should not be directly aware of their existence. If you need to refer to the document, use the information within the document to answer the user's question.
            //   If you can't find a good answer to a user's question in uploaded documentation, answer it concisely, according to information you know about the ${industry} process. Remember, YOU are a subject matter expert.
            //   `
            }
          );
        let r = false
        
        //poll for response for up to 15 seconds
        let loading_time = 0;
        const max_wait_time = 15000;
        while (!r && loading_time < max_wait_time) {
            console.log("polling response")
            r = await this.pollResponse(run.id);
            console.log(r)
            //pause .25 seconds
            await new Promise(r => setTimeout(r, 50));
            if (loading_time >= 4000){ //after 4 seconds, change loading icon
                this.setState({ still_loading: true });
            } else if (loading_time >= max_wait_time){ //after max time, show error message
                this.setState({ last_response_failed: true });
            }
            loading_time += 50;
        }
        console.log("Last message took " + loading_time + " milliseconds")
        this.setState({response_loading: false, still_loading: false, last_response_failed: false})
        return r;
        //console.log(this.state.messages)
    };

    //You can periodically retrieve the Run to check on its status to see if it has moved to completed
    //Once the Run completes, you can retrieve the Messages added by the Assistant to the Thread.
    pollResponse = async (run_id) => {
        const {thread} = this.state;
        const run = await this.openai.beta.threads.runs.retrieve(thread.id, run_id);
        console.log("run: ", run)
        if (run.status === "completed") {
            console.log("run completed..")
            const messages = await this.openai.beta.threads.messages.list(thread.id);
            //console.log("messages: ", messages)
            this.setState({ messages: messages, response_loading: false });
            return true;
        } else {
            console.log("run not completed..")
            return false;
        }
    }

    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };

    sendIntroductionMessage = async () => {
        const {inputValue, thread, assistant, custom_intro} = this.state;
        let intro_message;
        if(custom_intro){
            intro_message = custom_intro;
        } else if(this.context.haschatted){
            intro_message = "I'm back.";
        } else {
            intro_message = "introduce yourself";
        }
        if (thread && assistant) {
            const message = await this.openai.beta.threads.messages.create(
                thread.id,
                {
                    role: "user",
                    content: intro_message,
                }
            );
            console.log("sending message: ", message)
            const threadMessages = await this.openai.beta.threads.messages.list(
                thread.id
            );
            
            console.log(threadMessages.data);
            this.setState({thread: thread, messages:threadMessages, response_loading: true, last_response_failed: false})
            
            const received = await this.createResponse();
            console.log("received: ", received)
            
            this.context.updateHasChatted(true);

            if (!received) {
                console.log("received response")
                this.setState({last_response_failed: true})
            }
        }
    }


    handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        try{
            console.log("handle submit")
            const {inputValue, thread, assistant} = this.state;
            if (thread && assistant) {
                const message = await this.openai.beta.threads.messages.create(
                    thread.id,
                    {
                        role: "user",
                        content: inputValue,
                    }
                );
                console.log("sending message: ", message)
                const threadMessages = await this.openai.beta.threads.messages.list(
                    thread.id
                );
                
                console.log(threadMessages.data);
                this.setState({thread: thread, messages:threadMessages, inputValue: '', response_loading: true, last_response_failed: false})
                
                const received = await this.createResponse();
                console.log("received: ", received)
                if (!received) {
                    console.log("received response")
                    this.setState({last_response_failed: true})
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    //reverse map messages to <p> tags. access each message with messages.data[index].content[index].text.value
    getLocalMessages = () => {
        const { messages, custom_intro, response_still_loading } = this.state;
        console.log("get local messages");
        console.log(messages);
        if (messages.data) {
            const reversedMessages = [...messages.data].reverse(); // Create a reversed copy of the array
            let response = reversedMessages.map((message, index) => {
                if (index === 0 && !custom_intro) { //first introductory message will be hidden to user, unless a custom intro is provided
                    return null
                }
                const role = message.role;
                const classname = 'm-small message ' + role;
                const is_last_message = index === 0;
                const response_err_message = (this.state.last_response_failed && is_last_message) ? <p className='gpt-error'>Sorry, your request timed out. Please try again.</p> : null;
                const response_still_loading = (this.state.still_loading && is_last_message) ? <p className='pulse-text small'>Still working, almost there...</p> : null;
                const assistant_icon = <img src={assistantIcon} alt='assistant' className='icon'/>
                const user_icon = <FontAwesomeIcon icon={faUserCircle} color='black' className='icon'/>
                const icon = (role === 'user') ? user_icon : assistant_icon;
                let message_text = message.content[0].text.value;
                const formatted_text = message_text.replace(/\u3010.*?\u3011/g, '');
                console.log(formatted_text)
                const chat_item =          
                    <div className='gpt-message-item'>
                        {icon}
                        <p key={message_text} className={classname}>{formatted_text}</p>
                        {response_still_loading}
                        {response_err_message}

                    </div>    
                return (
                    <FadeInItem key={index} item={chat_item} />
                );
            });
            return response;
        } /*else {
            return(
                <div className= "gpt-message-item">
                    <img src={assistantIcon} alt='assistant' className='icon'/>
                    <p className='m-small message'>Hi! I'm John, your quote assistant. Ask me anything on your mind.</p>
                </div>
            )
        }*/
    };

    //when a prompt is clicked, the prompt's gpt_query is sent to the assistant
    //the prompt is then removed from state.prompts
    handlePromptClick = (clicked_query) => {
        // First, update the inputValue and send the handleSubmit call
        this.setState({ inputValue: clicked_query }, this.handleSubmit);
    
        // Then remove the prompt using its gpt_query
        this.setState(prevState => ({
            prompts: prevState.prompts.filter(prompt => prompt.gpt_query !== clicked_query)
        }));
    };


    render() {
        const { show, thread, inputValue, messages, response_loading, mounted } = this.state;
        const response_err_message = (this.state.last_response_failed) ? <p className='gpt-error'>Sorry, something went wrong. Please try again.</p> : null;
        const response_still_loading = (this.state.still_loading) ? <p className='pulse-text light m-small'>Almost there...</p> : null;
        const submit_icon = <FontAwesomeIcon 
                                icon={faPaperPlane}
                                color='black'
                                className={(response_loading) ? 'small-pulse-fade' : ''}
                            />

        console.log("new render")
        return (
            <div className='gpt-chat-container'>
                <div className='gpt-thread-container'>
                    <div className='gpt-thread'>
                        {this.getLocalMessages()}
                    </div>
                    <div className='gpt-prompt-container'>
                        {this.displayPrompts()}
                    </div>
                    {response_loading ? 
                        response_still_loading ?
                            <div className='gpt-loading'>
                                {response_still_loading}
                            </div>:
                            <div className='gpt-loading'>
                                <LoadingIcon classname='gpt-loading'/>
                            </div>
                     : null}
                </div>
                <form className='gpt-inputwrapper' onSubmit={this.handleSubmit}>
                    <input type="text" value={inputValue} onChange={this.handleInputChange} placeholder={`What's on your mind?`} data-matomo-unmask/>
                    <button type="submit" className='medium' disabled={response_loading}>{submit_icon}</button>
                </form>
                {response_err_message}
            </div>
        );
    }
}

export default GPTChatSession;
